export const INPUT_COMMON_PROPS = {
  isRequired: true,
  size: 'md',
  type: 'text',
  colorScheme: 'blackAlpha',
  borderRadius: '0',
  backgroundColor: 'bgSecondary',
  color: 'alternativeDark',
  border: 'none',
  _placeholder: { color: 'alternativeDark' },
  _focus: { borderColor: 'white' },
};

export const FORM_LABEL_PROPS = {
  fontSize: 'md',
  color: 'mainColorText',
  margin: '8px 0 0 0',
};

export const SELECT_COMMON_PROPS = {
  borderRadius: '0',
  size: 'md',
  variant: 'outline',
  backgroundColor: 'bgSecondary',
  color: 'mainColorTextLight',
  fontFamily: 'body',
  fontSize: 'md',
  border: 'none',
  borderRight: '1px solid white',
  _focus: { borderColor: 'white' },
};

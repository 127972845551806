import React, { useState } from 'react';
import { func, string } from 'prop-types';
import { BiArrowBack } from 'react-icons/bi';
import {
  Box,
  Heading,
  Text,
  FormControl,
  Input,
  Checkbox,
  FormLabel,
  Textarea,
  Button,
  Center,
  useToast,
  MenuButton,
  Menu,
  InputGroup,
  Icon,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { BiChevronDown } from 'react-icons/bi';

import useInvitation from '@hooks/useInvitation';
import useFormFields from '@/hooks/useFormFields';
import useGiftConfirmation from '@/usecase/use-gift-confirmation';
import { useGuest } from '@/context/guest';

import { COUNTRY_CODES } from '@/constants/country-codes';
import { BUTTON_PADDING_PROPS, BUTTON_PROPS } from '@/constants/colors';
import { BG_CONFIRMATION, ASSETS_BG_PATTERN } from '@/constants/assets';
import { ENABLE_SEND_GIFT, ENABLE_BANK_TRANSFER } from '@/constants/feature-flags';

import txtWording from './locales';

import { INPUT_COMMON_PROPS, FORM_LABEL_PROPS, SELECT_COMMON_PROPS } from './types';

function Confirmation({ lang, onBack }) {
  const [isGiftCard, setIsGiftCard] = useState(false);
  const [isBankTransfer, setIsBankTransfer] = useState(false);
  const toast = useToast();
  const { onPostConfirmation, loading } = useGiftConfirmation();
  const { guest, isRegistered } = useGuest();
  const { name, phone_number, country_code } = guest;
  const isInvitation = useInvitation();
  const invitation = useInvitation();

  const { formFields, createChangeHandler, onResetForm } = useFormFields({
    name: isRegistered ? name : isInvitation ? '' : name,
    isGift: false,
    note: '',
    phone_number: phone_number,
  });

  const [selectedCountryCode, setSelectedCountryCode] = useState(country_code || '62');

  const handleCountryCodeChange = (code) => {
    setSelectedCountryCode(code);
  };

  console.log(selectedCountryCode);

  /**
   * @function handleSendConfirmation
   * @description a function handler for submit confirmation
   */
  const handleSendConfirmation = async () => {
    if (!formFields.name) {
      toast({
        title: 'Oops!',
        description: txtWording.fillNameFirst[lang],
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    await onPostConfirmation({
      name: formFields.name,
      countryCode: selectedCountryCode,
      phone_number: formFields.phone_number,
      notes: formFields.note,
      isBankTransfer: String(isBankTransfer).toUpperCase(),
      isGiftCard: String(isGiftCard).toUpperCase(),
      onSuccess: () => {
        onResetForm();
        toast({
          title: txtWording.submitted[lang],
          description: txtWording.successSent[lang],
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      },
      onError: () => {
        toast({
          title: 'Oops!',
          description: txtWording.failedSent[lang],
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      },
    });
  };

  return (
    <Box
      minHeight="100vh"
      bgPosition="center"
      padding="32px"
      bgColor="bgPrimary"
      bgSize="cover"
      bgImage={`url(${BG_CONFIRMATION})`}
      bgPos="center"
    >
      <Box
        bgColor="transparent"
        border="2px solid"
        borderColor="secondaryColorText"
        padding="24px 16px"
        borderRadius="24px"
        boxShadow="md"
        bgImage={`url('${ASSETS_BG_PATTERN}')`}
        bgSize="contain"
      >
        {/* Heading & Desc Section */}
        <Box textAlign="center" color="mainColorText">
          <Heading
            fontWeight="normal"
            size="xl"
            textTransform="lowercase"
            letterSpacing="1px"
            fontFamily="CustomFont"
            color="secondaryColorText"
          >
            {txtWording.title[lang]}
          </Heading>
          <Text fontSize="md" padding="16px 0 ">
            {txtWording.desc[lang]}
          </Text>
        </Box>
        <Box>
          <FormControl margin="8px 0">
            <FormLabel {...FORM_LABEL_PROPS}>{txtWording.name[lang]}:</FormLabel>
            <Input
              {...INPUT_COMMON_PROPS}
              placeholder="..."
              value={formFields.name}
              onChange={createChangeHandler('name')}
            />
          </FormControl>
          <FormControl margin="8px 0">
            <FormLabel {...FORM_LABEL_PROPS}>{txtWording.hp[lang]}:</FormLabel>
            <InputGroup size="sm">
              <Menu>
                <MenuButton
                  {...SELECT_COMMON_PROPS}
                  as={Button}
                  rightIcon={<Icon as={BiChevronDown} />}
                >
                  {`+${selectedCountryCode}`}
                </MenuButton>
                <MenuList maxHeight="200px" overflowY="scroll">
                  {COUNTRY_CODES.map((country) => (
                    <MenuItem
                      key={country.dial_code}
                      onClick={() => handleCountryCodeChange(country.dial_code)}
                    >
                      {`${country.name}: +${country.dial_code}`}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
              <Input
                {...INPUT_COMMON_PROPS}
                placeholder="..."
                type="number"
                value={formFields.phone_number}
                onChange={createChangeHandler('phone_number')}
              />
            </InputGroup>
          </FormControl>
          <FormControl margin="24px 0">
            <FormLabel {...FORM_LABEL_PROPS}>{txtWording.giftType[lang]}:</FormLabel>
            {ENABLE_BANK_TRANSFER && (
              <Box>
                <Checkbox
                  size="md"
                  borderColor="secondaryColorText"
                  value={isBankTransfer}
                  onChange={() => setIsBankTransfer(!isBankTransfer)}
                  color="mainColorText"
                >
                  {txtWording.bank[lang]}
                </Checkbox>
              </Box>
            )}
            {ENABLE_SEND_GIFT && (
              <Box>
                <Checkbox
                  value={isGiftCard}
                  borderColor="secondaryColorText"
                  size="md"
                  onChange={() => setIsGiftCard(!isGiftCard)}
                  color="mainColorText"
                >
                  {txtWording.gift[lang]}
                </Checkbox>
              </Box>
            )}
          </FormControl>
          <FormControl margin="8px 0">
            <FormLabel {...FORM_LABEL_PROPS}>{txtWording.notes[lang]}:</FormLabel>
            <Textarea
              {...INPUT_COMMON_PROPS}
              value={formFields.note}
              placeholder="..."
              onChange={createChangeHandler('note')}
            />
          </FormControl>
        </Box>
        <Box marginTop="24px">
          <Center>
            <Button
              isLoading={loading}
              fontWeight="normal"
              color="alternativeDark"
              bgColor="bgSecondary"
              fontFamily="body"
              onClick={handleSendConfirmation}
              {...BUTTON_PROPS}
              {...BUTTON_PADDING_PROPS}
              textTransform="capitalize"
              size="sm"
            >
              {txtWording.sendConfirm[lang]}
            </Button>
          </Center>
        </Box>
      </Box>
      <Box paddingTop="24px">
        <Center>
          <Button
            leftIcon={<BiArrowBack />}
            type="button"
            size="sm"
            fontWeight="normal"
            color="white"
            colorScheme="blackAlpha"
            letterSpacing="2px"
            onClick={onBack}
          >
            {invitation ? txtWording.backToHome[lang] : txtWording.backToAnnoncement[lang]}
          </Button>
        </Center>
      </Box>
      <Box height="50px" />
    </Box>
  );
}

Confirmation.propTypes = {
  lang: string,
  onBack: func.isRequired,
};

Confirmation.defaultProps = {
  lang: 'id',
};

export default Confirmation;
